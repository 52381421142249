import { ReactNode } from "react";

// Base

export interface BaseModalProps {
  isOpen: boolean;
  onClose: VoidFunction;
}

// Common

export interface SelectOption<T extends string | number = string> {
  label: string;
  value: T;
}

export interface TabsOption<TabsValue = string> {
  label: string;
  value: TabsValue;
  isVisible?: boolean;
  component: ReactNode;
}

export interface Pagination {
  page: number;
  pageSize: number;
  total: number;
}

export type DetailsPageTabs<Tabs extends string, Component = ReactNode> = Record<
  Tabs,
  { isVisible: boolean; component: Component }
>;

// Enums

export enum PageTypes {
  VendorOpportunities = "vo",
  CreditSubmission = "cs",
}

export enum ContentViewType {
  Desktop = "Desktop",
  Mobile = "Mobile",
}

// Utils

export type ValueOf<T, K extends keyof T> = T[K];
