import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  LinearProgress,
  Radio,
  RadioGroup,
  Typography,
} from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import PhoneIcon from "@material-ui/icons/Phone";
import EmailIcon from "@material-ui/icons/Email";
import InfoIcon from "@material-ui/icons/Info";
import { RecertificationFormValues, RecertificationOption } from "../../types";
import { iniitialRecertificationFormValues } from "../../constants";
import { ReactComponent as FilePDF } from "../../../../assets/svg/file-pdf.svg";
import DateFnsUtils from "@date-io/date-fns";
import { MuiPickersUtilsProvider, KeyboardDatePicker, KeyboardDateTimePicker } from "@material-ui/pickers";
import { DropzoneDialog } from "material-ui-dropzone";
import { Task } from "@trnsact/trnsact-shared-types";
import { taskRegardingValues } from "pages/Prequal/constants";
import { UploadedFileView } from "components/shared/UploadedFileView/UploadedFileView";

interface Props {
  isOpen: boolean;
  isLoading: boolean;
  onClose: VoidFunction;
  onSave: any;
  applicantName: string;
  contact?: any;
  vendorOpportunityTasks: Task[];
  defaultFormValues: RecertificationFormValues;
}

export const RequestRecertificationModal = ({
  isOpen,
  isLoading,
  onClose,
  onSave,
  applicantName,
  contact,
  vendorOpportunityTasks,
  defaultFormValues,
}: Props) => {
  const classes = useStyles();

  const [recertificationFormValues, setRecertificationFormValues] = useState<RecertificationFormValues>(
    iniitialRecertificationFormValues
  );
  const [isUploadZoneOpen, setIsUploadZoneOpen] = useState<boolean>(false);
  const contacAlreadyHasSignatureTask =
    vendorOpportunityTasks &&
    !!vendorOpportunityTasks.find((t: Task) => {
      return (
        t.ownerPgId === contact?.ownerPgId && t.regarding === taskRegardingValues.signatures && t.status !== "complete"
      );
    });

  useEffect(() => {
    if (isOpen) {
      setRecertificationFormValues(defaultFormValues || iniitialRecertificationFormValues);
    }
  }, [isOpen]);

  const handleClose = () => {
    onClose();
  };

  const handleSave = () => {
    onSave(recertificationFormValues);
  };

  const handleUploadClick = () => {
    setIsUploadZoneOpen(true);
  };

  return (
    <Dialog open={isOpen} onClose={handleClose} fullWidth maxWidth="md">
      <DialogTitle>
        Request Recertification
        <Divider style={{ marginTop: 10 }} />
      </DialogTitle>
      {!isLoading && (
        <DialogContent>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="body1">Choose option</Typography>
            </Grid>
            <Grid item xs={12}>
              <FormControl component="fieldset">
                <RadioGroup
                  value={recertificationFormValues.recertificationOption}
                  onChange={e =>
                    setRecertificationFormValues({
                      ...recertificationFormValues,
                      recertificationOption: e.target.value as RecertificationOption,
                    })
                  }
                  className={classes.radioGroup}
                >
                  <FormControlLabel
                    value="document"
                    control={<Radio color="primary" />}
                    label={<DocumentOptionRadioLabel />}
                  />
                  <FormControlLabel
                    value="verbal-authorization"
                    control={<Radio color="primary" />}
                    label={<VerbalAuthorizationOptionRadioLabel />}
                  />
                  <FormControlLabel
                    value="send-task"
                    control={<Radio color="primary" />}
                    label={<SendTaskOptionRadioLabel contacAlreadyHasSignatureTask={contacAlreadyHasSignatureTask} />}
                    disabled={contacAlreadyHasSignatureTask}
                  />
                </RadioGroup>
              </FormControl>
            </Grid>

            {recertificationFormValues.recertificationOption === "send-task" && (
              <Grid item container style={{ marginTop: 10, paddingBottom: 0 }}>
                <Grid item xs={12}>
                  <Typography>Applicant: {applicantName}</Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography>
                    Contact: {contact?.firstName} {contact?.lastName}
                  </Typography>
                </Grid>
              </Grid>
            )}

            {["document", "verbal-authorization"].includes(recertificationFormValues.recertificationOption) && (
              <>
                <Grid item xs={12}>
                  <MuiPickersUtilsProvider utils={DateFnsUtils}>
                    {/*                 <KeyboardDatePicker
                  fullWidth
                  variant="inline"
                  label="Date of Application"
                  format="MM/dd/yyyy"
                  value={recertificationFormValues.signatureDate}
                  onChange={date =>
                    setRecertificationFormValues({ ...recertificationFormValues, signatureDate: date ? date.toDateString() : null })
                  }
                /> */}
                    <KeyboardDateTimePicker
                      fullWidth
                      disableToolbar
                      variant="inline"
                      label="Signature Date"
                      format="MM/dd/yyyy hh:mm a"
                      value={recertificationFormValues.signatureDate}
                      onChange={date =>
                        setRecertificationFormValues({
                          ...recertificationFormValues,
                          signatureDate: date && !isNaN(date.getTime()) ? date.toISOString() : null,
                        })
                      }
                      required
                      KeyboardButtonProps={{
                        "aria-label": "change date",
                      }}
                    />
                  </MuiPickersUtilsProvider>
                </Grid>

                <Grid item xs={12}>
                  {recertificationFormValues.file && (
                    <UploadedFileView
                      onDelete={() =>
                        setRecertificationFormValues({
                          ...recertificationFormValues,
                          file: null,
                        })
                      }
                      name={recertificationFormValues.file.name}
                    />
                  )}
                  {!recertificationFormValues.file && (
                    <>
                      <Button variant="contained" color="primary" size="small" onClick={handleUploadClick}>
                        Upload Credit Application
                      </Button>
                      <DropzoneDialog
                        open={isUploadZoneOpen}
                        onDrop={file => {
                          setRecertificationFormValues({
                            ...recertificationFormValues,
                            file: file[0],
                          });
                          setIsUploadZoneOpen(false);
                        }}
                        onSave={file => {
                          setRecertificationFormValues({
                            ...recertificationFormValues,
                            file: file[0],
                          });
                          setIsUploadZoneOpen(false);
                        }}
                        onClose={() => {
                          setIsUploadZoneOpen(false);
                        }}
                        filesLimit={1}
                        acceptedFiles={["application/pdf"]}
                        dropzoneText={"Drag or Click Here To Upload File"}
                        dialogTitle="Upload Credit Application"
                        showPreviews={false}
                        maxFileSize={5000000}
                      />
                    </>
                  )}
                </Grid>
              </>
            )}
          </Grid>
        </DialogContent>
      )}

      <DialogActions>
        {!isLoading ? (
          <>
            <Button variant="outlined" color="primary" size="small" onClick={handleClose}>
              Cancel
            </Button>
            <Button variant="contained" color="primary" size="small" onClick={handleSave}>
              Save
            </Button>
          </>
        ) : (
          <LinearProgress style={{ width: "100%" }} />
        )}
      </DialogActions>
    </Dialog>
  );
};

const DocumentOptionRadioLabel = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.optionRadioLabel}>
      <Grid item container alignItems="center" xs={12}>
        <FilePDF style={{ marginRight: 4, fill: "#616161", width: 24 }} />
        <Typography variant="subtitle2">Document</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">You received a Credit Application and can upload it</Typography>
      </Grid>
    </Grid>
  );
};

const VerbalAuthorizationOptionRadioLabel = () => {
  const classes = useStyles();

  return (
    <Grid container className={classes.optionRadioLabel}>
      <Grid item container alignItems="flex-end" xs={12}>
        <PhoneIcon className={classes.optionRadioLabelIcon} />
        <Typography variant="subtitle2">Verbal authorization</Typography>
      </Grid>
      <Grid item>
        <Typography variant="body1">
          You have verbally reviewed the information with the Applicant and they have given you verbal authorization to
          sign on update the application on their behalf.
        </Typography>
      </Grid>
    </Grid>
  );
};

const SendTaskOptionRadioLabel = ({ contacAlreadyHasSignatureTask }: { contacAlreadyHasSignatureTask: boolean }) => {
  const classes = useStyles();

  return (
    <Grid container className={classes.optionRadioLabel}>
      <Grid item container alignItems="flex-end" xs={12}>
        <EmailIcon className={classes.optionRadioLabelIcon} />
        <Typography variant="subtitle2">Send task</Typography>
        {contacAlreadyHasSignatureTask && (
          <>
            <InfoIcon className={classes.optionRadioInfoIcon} />
            <Typography variant="caption">The contact already has an active task</Typography>
          </>
        )}
      </Grid>
      <Grid item>
        <Typography variant="body1">
          Send Task to applicant for them to review previously supplied information and update application.
        </Typography>
      </Grid>
      {/*       {contacAlreadyHasSignatureTask && <Grid item>
        <InfoIcon color="primary" />
        <Typography variant="body1">
          The contact already has an active task
        </Typography>
      </Grid>} */}
    </Grid>
  );
};

const useStyles = makeStyles({
  radioGroup: {
    "& .MuiFormControlLabel-root": {
      alignItems: "flex-start",
      "&:not(:last-child)": {
        marginBottom: 12,
      },
    },
    "& .MuiRadio-root": {
      paddingTop: 0,
    },
  },
  optionRadioLabel: {
    color: "black",
    "& h6": {
      textTransform: "none",
    },
  },
  optionRadioLabelIcon: {
    color: "#616161",
    marginRight: 4,
  },
  optionRadioInfoIcon: {
    color: "#616161",
    margin: "0 5px 0 15px",
    width: 18,
  },
});
