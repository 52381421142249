import formatString from "format-string-by-pattern";

export const formatSSNInput = (inputValue: string): string => {
  return inputValue ? formatString("999-99-9999", inputValue.replace(/[^0-9]/g, "")) : "";
};

export const formatFloatInput = (inputValue: string, precision?: number, maxValue?: number): string => {
  let formattedValue = inputValue ? inputValue.replace(/[^0-9.]/g, "").replace(/\.+/, ".") : "";

  // cut to precision
  if (formattedValue && precision && formattedValue.split(".")[1]?.length > precision) {
    formattedValue = formattedValue.substring(0, formattedValue.length - 1);
  }

  // limit to maxValue
  if (formattedValue && maxValue && !formattedValue.endsWith(".") && parseFloat(formattedValue) > maxValue) {
    formattedValue = maxValue.toString();
  }

  return formattedValue;
};

export const formatPhoneInput = (inputValue: string): string => {
  return inputValue ? formatString("(999) 999-9999", inputValue.replace(/[^0-9]/g, "")) : "";
};

export const formatPostalCodeInput = (inputValue: string): string => {
  const POSTAL_CODE_LENGTH = 5;

  let formattedValue = inputValue ? inputValue.replace(/[^\d]/g, "") : "";
  if (formattedValue.length > POSTAL_CODE_LENGTH) {
    return formattedValue.slice(0, -1);
  }
  return formattedValue;
};
