import React, { ReactNode, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { makeStyles } from "@material-ui/core/styles";
import { Checkbox, IconButton, Button } from "@material-ui/core";
import Table from "components/Table";
import TableHeader from "components/Table/TableHeader";
import TableSearch from "components/Table/TableSearch";
import ConfirmationDialog from "components/ConfirmationDialog";
import { ConfigurableItemActions } from "pages/PortalConfigurationWizard/CreditAppDetailModal/ConfigurableItemActions";
import { AddOutlined } from "@material-ui/icons";
import CreateEditChecklistItemModal from "./CreateEditChecklistItemModal";
import { format } from "date-fns";
import { v4 as uuidv4 } from "uuid";
import _ from "lodash";
import { ContentViewType } from "../../global";
import { EntityMobileCards } from "../shared/EntityMobileCards";
import EditIcon from "@material-ui/icons/Edit";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import { useViewTypeContext } from "../../contexts/contentViewType";
import { CardContainer } from "../shared/CardContainer/CardContainer";
import { ChecklistItem, ChecklistItemMobileEntity } from "./type";
import { mobileEntityAdapter } from "./lib/mobileEntityAdapter";

interface Props {
  checklist: ChecklistItem[];
  isPortalSetupPage?: boolean;
  onChecklistSave: (nextValue: ChecklistItem[]) => void;
}

const ChecklistTemplate = ({ checklist, onChecklistSave, isPortalSetupPage = false }: Props) => {
  const { contentViewType } = useViewTypeContext();

  const classes = useStyles();

  const userProfile = useSelector((state: any) => state.userProfile);
  const account = useSelector((state: any) => state.account);

  const searchRef = useRef({ prevSearch: undefined, currentSearch: "" });
  const [pageNumber, setPageNumber] = useState<number>(0);
  const [pageSizeValue, setPageSizeValue] = useState<number>(5);
  const [savedListSettings, setSavedListSettings] = useState<any>({ page: 0, pageSize: 20 });

  const [isCreateEditChecklistItemModalOpen, setIsCreateEditChecklistItemModalOpen] = useState<boolean>(false);
  const [isConfirmationModalOpen, setIsConfirmationModalOpen] = useState<boolean>(false);
  const [checklistItemToEdit, setChecklistItemToEdit] = useState<ChecklistItem | null>(null);

  const columns = [
    {
      Header: "Completed",
      Cell: (props: any) => (
        <Checkbox
          color="primary"
          onChange={() => handleChecklistItemToggle(props.original)}
          checked={props.original.isCompleted}
          disabled={isPortalSetupPage}
        />
      ),
      width: 100,
    },
    {
      Header: "Task Name",
      accessor: "name",
    },
    {
      Header: "Created On",
      accessor: "createdDateTime",
      Cell: ({ value }: any) => (value ? format(new Date(value), "M/dd/yyyy") : ""),
    },
    {
      Header: "Completed On",
      accessor: "completedDateTime",
      Cell: ({ value }: any) => (value ? format(new Date(value), "h:mmaa MM/dd/yyyy") : ""),
    },
    {
      Header: "Completed By",
      accessor: "completedByUserProfileId",
      Cell: ({ value }: any) => (value ? _.get(_.find(account?.contacts, { id: value }), "fullName") : ""),
    },
    {
      Header: "Notes",
      accessor: "notes",
    },
    {
      Header: "Actions",
      Cell: (props: any) => (
        <ConfigurableItemActions
          itemType="Checklist Item"
          onEdit={(e: any) => handleEditChecklistItemClick(e, props.original)}
          onDelete={(e: any) => handleDeleteChecklistItemClick(e, props.original)}
        />
      ),
      width: 150,
      getProps: () => {
        return {
          className: "actions-column",
        };
      },
    },
  ];

  const handleCreateChecklistItemClick = () => {
    const emptyChecklistItem: ChecklistItem = {
      name: "",
      notes: "",
      isCompleted: false,
      completedDateTime: null,
      completedByUserProfileId: null,
      createdDateTime: new Date(),
      modifiedDateTime: new Date(),
    };

    setChecklistItemToEdit(emptyChecklistItem);
    setIsCreateEditChecklistItemModalOpen(true);
  };

  const handleEditChecklistItemClick = (e: any, checklistItemToEdit: any) => {
    const checklistItemToEditCopy = _.cloneDeep(checklistItemToEdit);
    setChecklistItemToEdit(checklistItemToEditCopy);
    setIsCreateEditChecklistItemModalOpen(true);
  };

  const handleDeleteChecklistItemClick = (e: any, checklistItemToRemove: any) => {
    setChecklistItemToEdit(checklistItemToRemove);
    setIsConfirmationModalOpen(true);
  };

  const onConfiramtionModalClose = (event: any, reason: any) => {
    if (_.includes(["backdropClick", "escapeKeyDown"], reason)) {
      return;
    }

    setIsConfirmationModalOpen(false);
    if (event.result === "ok") {
      const checklistToSave = checklist.filter((c: any) => c.checklistItemId !== checklistItemToEdit?.checklistItemId);
      onChecklistSave(checklistToSave);
    }
  };

  const handleChecklistItemToggle = (checklistItem: any) => {
    const isCompleted = !checklistItem.isCompleted;
    const checklistToSave = checklist.map((c: any) =>
      c.checklistItemId === checklistItem.checklistItemId
        ? {
            ...checklistItem,
            isCompleted,
            completedDateTime: isCompleted ? new Date() : null,
            completedByUserProfileId: isCompleted ? userProfile?.id : null,
          }
        : c
    );
    onChecklistSave(checklistToSave);
  };

  const handleSaveChecklistItem = () => {
    const isEditing = !!checklistItemToEdit?.checklistItemId;
    const checklistToSave = isEditing
      ? checklist.map((c: any) =>
          c.checklistItemId == checklistItemToEdit.checklistItemId
            ? { ...checklistItemToEdit, modifiedDateTime: new Date() }
            : c
        )
      : [...checklist, { ...checklistItemToEdit, checklistItemId: uuidv4() }];
    onChecklistSave(checklistToSave);
    setIsCreateEditChecklistItemModalOpen(false);
  };

  const renderByViewType: Record<ContentViewType, ReactNode> = {
    [ContentViewType.Mobile]: (
      <EntityMobileCards<ChecklistItem, ChecklistItemMobileEntity>
        entities={checklist}
        entityAdapter={entity => mobileEntityAdapter(entity, account)}
        isSelectEntityDisabled={isPortalSetupPage}
        onSelectEntity={entity => handleChecklistItemToggle(entity.original)}
        actionsPanel={({ original }) => (
          <>
            <IconButton size="small" onClick={() => handleEditChecklistItemClick(null, original)}>
              <EditIcon fontSize="small" color="primary" />
            </IconButton>

            <IconButton size="small" onClick={() => handleDeleteChecklistItemClick(null, original)}>
              <DeleteOutlineIcon fontSize="small" color="error" />
            </IconButton>
          </>
        )}
      />
    ),
    [ContentViewType.Desktop]: (
      <div>
        <>
          <TableHeader
            filterComps={
              <TableSearch
                setPageNumber={setPageNumber}
                setSavedListSettings={setSavedListSettings}
                searchRef={searchRef}
                savedListSettings={savedListSettings}
              />
            }
          >
            <Button
              size="small"
              color="primary"
              variant="outlined"
              onClick={handleCreateChecklistItemClick}
              startIcon={<AddOutlined />}
            >
              Add Checklist Item
            </Button>
          </TableHeader>

          <Table
            color="primary"
            data={checklist}
            columns={columns}
            pageSize={pageSizeValue}
            pageNumber={pageNumber}
            defaultPageSize={savedListSettings.pageSize}
            showPaginationTop={false}
            showPaginationBottom={true}
            onPageChange={setPageNumber}
            onPageSizeChange={(changePageSize: any) => {
              setPageSizeValue(changePageSize);
              setPageNumber(0);
            }}
            className={classes.checklistTemplatesTable + " -highlight"}
          />
        </>
      </div>
    ),
  };

  return (
    <>
      <CardContainer title="Checklist">{renderByViewType[contentViewType]}</CardContainer>

      <CreateEditChecklistItemModal
        isOpen={isCreateEditChecklistItemModalOpen}
        setIsOpen={setIsCreateEditChecklistItemModalOpen}
        checklistItem={checklistItemToEdit}
        setChecklistItem={setChecklistItemToEdit}
        handleSave={handleSaveChecklistItem}
      />

      <ConfirmationDialog
        title="Confirmation"
        message="Are you sure you want to remove checklist item?"
        open={isConfirmationModalOpen}
        onClose={onConfiramtionModalClose}
      />
    </>
  );
};

export default ChecklistTemplate;

const useStyles = makeStyles({
  checklistTemplatesTable: {
    "& .actions-column": {
      display: "flex",
    },
  },
});
