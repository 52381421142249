import React, { useState } from "react";
import _ from "lodash";
import {
  CalculatePaymentProductAddonInput,
  CalculatePaymentPackageInput,
} from "@trnsact/trnsact-shared-types/dist/generated";
import "./AftermarketNtpProductComponent.css";
import NTP_LOGO from "../../../assets/img/aftermarket-vendor-logos/ntp-logo.png";
import { formatCurrency, formatNumberWithDelimiter } from "utils";
import DraggableComponent from "components/DragAndDrop/DraggableComponent";

interface AftermarketNtpProductComponentProps {
  jsonData: any;
}

const AftermarketNtpProductComponent: React.FC<AftermarketNtpProductComponentProps> = ({ jsonData }) => {
  // Initialize state based on jsonData
  const [data, setData] = useState(jsonData);
  const [mappedData, setMappedData] = useState<CalculatePaymentPackageInput | null>(null);

  const handleCheckboxChange = (componentIndex: number, lossCodeIndex: number, checked: boolean) => {
    // Update the state with the new checkbox status
    const updatedData = { ...data };
    updatedData.API_RESPONSE_DATA.terms[0].components[componentIndex].lossCodes[lossCodeIndex]._pqtSelected = checked;
    setData(updatedData);
    const newMappedData = mapDataToDraggableComponent(updatedData);
    setMappedData(newMappedData);
    console.log("Mapped Data: ", newMappedData);
  };

  function mapDataToDraggableComponent(dataToMap: any): CalculatePaymentPackageInput {
    const proposalProductPackage: CalculatePaymentPackageInput & { apiData: any } = {
      name: dataToMap.API_RESPONSE_DATA.description,
      // ordinal: 0,
      products: [
        {
          proposalProductId: "",
          retailCost: dataToMap.API_RESPONSE_DATA.terms[0].dealerCost,
          title: "Options",
          cost: dataToMap.API_RESPONSE_DATA.terms[0].dealerCost,
          // createdDateTime: null,
          // modifiedDateTime: null,
          addons: [],
        },
      ],
      apiData: dataToMap,
      proposalPackageId: dataToMap.API_RESPONSE_DATA.description,
      // createdDateTime: null,
      // modifiedDateTime: null,
    };
    // Assuming 'components' is an array from which we need to extract information
    const addons = dataToMap.API_RESPONSE_DATA.terms.flatMap((term: any) =>
      term.components.flatMap((component: any) =>
        component.lossCodes
          .filter((lossCode: any) => lossCode._pqtSelected)
          .map((lossCode: any) => {
            const addon: CalculatePaymentProductAddonInput = {
              category: component.description,
              cost: lossCode.dealerCost,
              title: lossCode.description,
              retailCost: lossCode.suggestedRetailCost,
            };
            return addon;
          })
      )
    );

    proposalProductPackage!.products![0]!.addons = addons;

    return proposalProductPackage;
  }

  return (
    <div className="draggable-container">
      <DraggableComponent
        data={mappedData}
        draggableComponent={
          <>
            <div
              className="description"
              draggable
              onDragStart={e => {
                const clonedData = _.cloneDeep(data);
                e.dataTransfer.setData("text/plain", JSON.stringify(clonedData));
              }}
            >
              <div>
                <img height={"48px"} width={"48px"} src={NTP_LOGO} alt="NTP Logo" />
                {data.API_RESPONSE_DATA.description}
              </div>
              <div style={{ fontSize: "18px", marginLeft: "64px" }}>
                {data.API_RESPONSE_DATA.terms[0].termMonths} Months /{" "}
                {formatNumberWithDelimiter(data.API_RESPONSE_DATA.terms[0].termOdometer)} Miles -{" "}
                {formatCurrency(data.API_RESPONSE_DATA.terms[0].dealerCost)}.00
              </div>
            </div>
            <div className="components-container">
              {data.API_RESPONSE_DATA.terms[0].components.map((component: any, componentIndex: number) => (
                <div key={component.description} className="draggable-box">
                  <b>{component.description}</b>
                  <ul>
                    {component.lossCodes.map((lossCode: any, lossCodeIndex: number) => (
                      <li key={lossCode.coverageLossCodeId}>
                        <label>
                          <input
                            type="checkbox"
                            checked={lossCode._pqtSelected || false}
                            onChange={e => handleCheckboxChange(componentIndex, lossCodeIndex, e.target.checked)}
                          />
                          {lossCode.description}
                        </label>
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
          </>
        }
      />
    </div>
  );
};

export default AftermarketNtpProductComponent;
