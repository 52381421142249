import { ContactFormValues, RecertificationFormValues } from "./types";

export const iniitialContactFormValues: ContactFormValues = {
  firstName: "",
  lastName: "",
  email: "",
  dateOfBirth: null,
  title: "",
  ssn: "",
  percOwner: "",
  workPhone: "",
  mobilePhone: "",
  homePhone: "",
  address: "",
  city: "",
  state: "",
  postalCode: "",
};

export const iniitialRecertificationFormValues: RecertificationFormValues = {
  recertificationOption: "document",
  signatureDate: null,
  file: null,
};
