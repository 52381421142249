import React, { PropsWithChildren, ReactNode } from "react";
import clsx from "clsx";
import { Skeleton } from "@material-ui/lab";
import { Paper, Box, Typography, Button, ButtonProps } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ContentViewType } from "../../../global";
import { isDesktop, isMobile } from "../../../utils/contentViewType";

interface Props {
  title: string;
  isLoading?: boolean;
  actionBtn?: ReactNode;
  containerClassName?: string;
  contentViewType?: ContentViewType;
  actionBtnProps?: ButtonProps & { label: string };
}

export const CardContainer = ({
  title,
  isLoading,
  children,
  actionBtn,
  actionBtnProps,
  containerClassName,
  contentViewType = ContentViewType.Desktop,
}: PropsWithChildren<Props>) => {
  const classes = useStyles();

  return (
    <Paper className={clsx(classes.container, containerClassName)}>
      {isLoading ? (
        <Skeleton variant="rect" height="100%" width="100%" />
      ) : (
        <>
          <Box
            className={clsx(classes.header, {
              [classes.headerDesktop]: isDesktop(contentViewType),
              [classes.headerMobile]: isMobile(contentViewType),
            })}
          >
            <Typography component="span" variant="h6">
              {title}
            </Typography>

            {actionBtn || (
              <>
                {actionBtnProps && (
                  <Button variant="outlined" color="primary" size="small" {...actionBtnProps}>
                    {actionBtnProps.label}
                  </Button>
                )}
              </>
            )}
          </Box>

          <Box>{children}</Box>
        </>
      )}
    </Paper>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "16px",
    height: "100%",
    padding: "24px",
    display: "flex",
    flexDirection: "column",
  },
  header: {
    display: "flex",
    alignItems: "flex-start",
    justifyContent: "space-between",
  },
  headerDesktop: {
    flexDirection: "row",
  },
  headerMobile: {
    gap: "8px",
    flexDirection: "column",
  },
});
