import React, { useState } from "react";
import { useDrop } from "react-dnd";

interface DragAndDropContainerProps {
  dropContainerComponent: React.ReactElement<{ droppedData: any }>;
}

const DragAndDropContainer: React.FC<DragAndDropContainerProps> = ({ dropContainerComponent }) => {
  const [droppedData, setDroppedData] = useState<any>(null);

  const [{ isOver }, drop] = useDrop(
    () => ({
      accept: "ITEM",
      drop: (item: { data: any }) => {
        // alert(`Dropped data: ${item.data}`);
        setDroppedData(item.data);
      },
      collect: monitor => ({
        isOver: !!monitor.isOver(),
      }),
    }),
    []
  );

  return (
    <div
      ref={drop}
      style={{
        // height: '200px',
        // width: '200px',
        backgroundColor: isOver ? "lightgreen" : "",
        // padding: '10px',
        // margin: '10px',
        // border: '1px solid gray',
      }}
    >
      {React.cloneElement(dropContainerComponent, { droppedData })}
    </div>
  );
};

export default DragAndDropContainer;
