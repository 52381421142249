import React, { useEffect, useState } from "react";
import {
  Box,
  Button,
  Divider,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  Tab,
  Tabs,
  TextField,
  Typography,
} from "@material-ui/core";
import AddIcon from "@material-ui/icons/Add";
import SaveIcon from "@material-ui/icons/Save";
import LooksOneIcon from "@material-ui/icons/LooksOne";
import LooksTwoIcon from "@material-ui/icons/LooksTwo";
import ArrowBackIosIcon from "@material-ui/icons/ArrowBackIos";
import DeleteOutlineIcon from "@material-ui/icons/DeleteOutline";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { connect } from "react-redux";
import CustomCurrentyInput from "../../components/CustomCurrentyInput";
import RemoveCircleIcon from "@material-ui/icons/RemoveCircle";
import { Switch, useHistory } from "react-router-dom";
import {
  BusinessRule,
  ConditionSettingsBoolean,
  ConditionSettingsNumeric,
  ConditionSettingsString,
  FACT_NAMES_BOOLEAN,
  FACT_NAMES_DATE,
  FACT_NAMES_NUMERIC,
  FACT_NAMES_STRING,
  NUMERIC_OPERATORS,
} from "@trnsact/business-criteria";
import _ from "lodash";
import {
  CANADA_REGION_CODES,
  EQUIPMENT_CONDITION,
  FINANCING_TYPE,
  LEGAL_STRUCTURE,
  US_REGION_CODES,
  AftermarketProductVendorApiChannel,
  AftermarketProductCategory,
  AftermarketProductType,
} from "@trnsact/trnsact-shared-types";
import ChipInput from "components/Generic/ChipInput/ChipInput";
import { AFTERMARKET_PRODUCT_EVENTS } from "@trnsact/business-criteria/dist/src/models/rule-event/aftermarket/aftermarket-product-events";
import { MARKUP_TYPE } from "@trnsact/business-criteria/dist/src/models/rule-event/aftermarket/markup-type";
import gql from "graphql-tag";
import { useLazyQuery, useMutation, useQuery } from "@apollo/react-hooks";

const Q_AFTERMARKET_PRODUCTS = gql`
  query GetAftermarketProducts($accountId: ID!) {
    aftermarketProducts(accountId: $accountId) {
      aftermarketProductId
      productName
      productCategory
      productDescriptionExternal
      criteria
      productDescriptionInternal
      productType
      createdDateTime
    }
  }
`;

const Q_PARTNER_LINKS = gql`
  query GetDealerLinks($vendorProfileId: ID!) {
    aftermarketDealerPartnerLink(vendorProfileId: $vendorProfileId) {
      partnerLinkId
      name
      partnerProfile {
        hasApi
        name
      }
    }
  }
`;

const M_CREATE_AFTERMARKET_PRODUCTS = gql`
  mutation CreateAftermarketProduct($input: CreateAftermarketProductInput!) {
    createAftermarketProduct(input: $input) {
      success
    }
  }
`;

const capitalize = (s: string) => {
  if (s) {
    if (s.length <= 4) return s.toUpperCase();
    return s
      .toLowerCase()
      .replace(/_/g, " ")
      .replace(/\b\w/g, char => char.toUpperCase());
  } else return s;
};

interface FactNumericType {
  value: FACT_NAMES_NUMERIC;
  label: string;
}

interface FactBooleanType {
  value: FACT_NAMES_BOOLEAN;
  label: string;
}

interface FactStringType {
  value: FACT_NAMES_STRING;
  label: string;
}
interface FactDataType {
  value: FACT_NAMES_DATE;
  label: string;
}

interface ConfigurationRow {
  conditionSettings: ConditionSettingsNumeric | ConditionSettingsBoolean | ConditionSettingsString;
}

interface Configuration {
  id?: string;
  name: string;
  configRows: ConfigurationRow[];
  type: AftermarketProductType;
  dealerCostSource: string;
  dealerCost: number;
  dealerMarkup: number;
  dealerMarkupType: MARKUP_TYPE;
  minPricing: number;
  maxPricing: number;
  suggestedRetailPricing: number;
}

const formatConditionToSave = (addedCondition: any, factTypes: string[]) => {
  if (
    factTypes.includes(_.get(addedCondition, "conditionSettings.fact")) &&
    !_.get(addedCondition, "conditionSettings.exactMatch").every((item: any) => typeof item === "string")
  ) {
    return addedCondition.conditionSettings.exactMatch.map((item: any) => item.code);
  }
  return addedCondition.conditionSettings.exactMatch;
};
const formatNames = (name: string) => {
  return name
    .replace(/([A-Z])/g, " $1")
    .replace(/And/g, "and")
    .trim();
};

const getStringFactInitialValues = (configRow: any): string[] => {
  const value = _.get(configRow, `conditionSettings.exactMatch`, [] as string[]);
  if (_.isArray(value)) {
    return value;
  }
  if (_.isString(value) && value !== "") {
    return [value];
  }
  return [];
};

const aftermarketVendorList = Object.keys(AftermarketProductVendorApiChannel).map(key => ({
  label: AftermarketProductVendorApiChannel[key as keyof typeof AftermarketProductVendorApiChannel],
}));
const aftermarketProductCategoryList = Object.keys(AftermarketProductCategory).map(key => ({
  value: AftermarketProductCategory[key as keyof typeof AftermarketProductCategory],
  label: capitalize(AftermarketProductCategory[key as keyof typeof AftermarketProductCategory]),
}));
const aftermarketProductTypeList = Object.keys(AftermarketProductType).map(key => ({
  value: AftermarketProductType[key as keyof typeof AftermarketProductType],
  label: capitalize(AftermarketProductType[key as keyof typeof AftermarketProductType]),
}));

function getRegionName(list: any, code: string): string | undefined {
  const entries = Object.entries(list) as [string, string][];
  const entry = entries.find(([key, value]) => value === code);
  return entry ? formatNames(entry[0]) : undefined;
}

function getLegalStructureName(list: any, code: string): string | undefined {
  const entries = Object.entries(list) as [string, string][];
  const entry = entries.find(([key, value]) => value === code);
  return entry ? capitalize(entry[0]) : undefined;
}
function getFinancingTypeName(list: any, code: string): string | undefined {
  return FINANCING_TYPE[code as keyof typeof FINANCING_TYPE];
}

const factNamesNumeric: FactNumericType[] = Object.keys(FACT_NAMES_NUMERIC).map(key => ({
  value: FACT_NAMES_NUMERIC[key as keyof typeof FACT_NAMES_NUMERIC],
  label: key
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/\b(\w)/g, char => char.toUpperCase()),
}));

const factNamesBoolean: FactBooleanType[] = Object.keys(FACT_NAMES_BOOLEAN).map(key => ({
  value: FACT_NAMES_BOOLEAN[key as keyof typeof FACT_NAMES_BOOLEAN],
  label: key
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/\b(\w)/g, char => char.toUpperCase()),
}));

const factNamesString: FactStringType[] = Object.keys(FACT_NAMES_STRING).map(key => ({
  value: FACT_NAMES_STRING[key as keyof typeof FACT_NAMES_STRING],
  label: key
    .replace(/_/g, " ")
    .toLowerCase()
    .replace(/\b(\w)/g, char => char.toUpperCase()),
}));

const numericOperatorNames = Object.keys(NUMERIC_OPERATORS).map(key => ({
  value: NUMERIC_OPERATORS[key as keyof typeof NUMERIC_OPERATORS],
  label: key
    .replace(/([A-Z])/g, " $1")
    .toLowerCase()
    .replace(/^\w/, char => char.toUpperCase()),
}));

const booleanOperatorNames = [
  { value: true, label: "Yes" },
  { value: false, label: "No" },
];

const AddAftermarketProduct = ({ userProfile, account, vp }: { userProfile: any; account: any; vp: any }) => {
  const { loading: loadingAftermarketProducts, data: { aftermarketProducts } = { aftermarketProducts: [] } } = useQuery(
    Q_AFTERMARKET_PRODUCTS,
    {
      fetchPolicy: "no-cache",
      variables: { accountId: account.id },
    }
  );
  const [configurations, setConfigurations] = useState<Configuration[]>([]);
  const [createAftermarketProduct] = useMutation(M_CREATE_AFTERMARKET_PRODUCTS, {
    context: { authRequired: true },
  });

  const { data: partnerLinks, refetch: partnerLinksRefetch, loading: loadingPartnerLinks } = useQuery(Q_PARTNER_LINKS, {
    variables: {
      vendorProfileId: vp.id,
    },
    onCompleted(data) {
      const dataReceived = _.get(data, "aftermarketDealerPartnerLink", []);
      if (dataReceived.length > 0) {
        setAvailableVendors(dataReceived.map((pl: any) => ({ label: pl.name, value: pl.partnerLinkId })));
      }
    },
  });
  const [availableFacts, setAvailableFacts] = useState<(FactBooleanType | FactNumericType | FactStringType)[]>([
    ...factNamesBoolean,
    ...factNamesNumeric,
    ...factNamesString,
  ]);

  const [activeTab, setActiveTab] = useState<number>(0);
  const [selectedVendor, setSelectedVendor] = useState<{ label: string; value: string } | null>(null);

  const [vendorOption, setVendorOption] = useState<"select" | "create">("select");
  const [availableVendors, setAvailableVendors] = useState<[]>([]);
  const [newVendorName, setNewVendorName] = useState<string>("");
  const [productName, setProductName] = useState<string>("");
  const [productType, setProductType] = useState<string>(AftermarketProductCategory.Gap);
  const [pricingMethod, setPricingMethod] = useState<string>("global");
  const [configurationMethod, setConfigurationMethod] = useState<number>(0);

  const history = useHistory();

  const handleSaveAftermarketProduct = async (e: any) => {
    e.preventDefault();

    let rules = [];
    rules = configurations.map(config => {
      const rule = new BusinessRule({
        name: config.name,
        eventParams: {
          type: AFTERMARKET_PRODUCT_EVENTS.AFTERMARKET_PRODUCT_QUALIFIED,
          params: {
            DEALER_COST: config.dealerCost,
            MARKUP: {
              AMOUNT: config.dealerMarkup,
              TYPE: config.dealerMarkupType,
            },
            PRICING_MAX: config.maxPricing,
            PRICING_MIN: config.minPricing,
            SUGGESTED_RETAIL_PRICE: config.suggestedRetailPricing,
          },
        },
      });
      config.configRows.map((cond: any) => {
        let addedCondition = _.cloneDeep(cond);

        addedCondition.conditionSettings.exactMatch = formatConditionToSave(addedCondition, [
          "CANADA_REGION",
          "USA_STATE",
          "LEGAL_STRUCTURE",
          "FINANCING_TYPE",
          "EQUIPMENT_CONDITION",
        ]);

        rule.Conditions.addCondition(addedCondition.conditionSettings);
      });

      return rule;
    });
    try {
      await createAftermarketProduct({
        variables: {
          input: {
            aftermarketVendorApiChannel: aftermarketVendorList.find(vendor => vendor.label === selectedVendor?.label)
              ?.label,
            criteria: rules.map(rule => rule.getRule()),
            partnerLinkIds: [selectedVendor?.value],
            productCategory: productType,
            productName,
            productType: AftermarketProductType.Product,
          },
        },
      });
    } catch (error) {
      console.log(error);
    }
  };
  const getNextRowElements = (
    configurationIndex: number
  ): ConditionSettingsNumeric | ConditionSettingsBoolean | ConditionSettingsString | null => {
    const chosenConfiguration = configurations[configurationIndex];
    //Pick configRows from the configuration that are not already selected
    const selectedFacts = new Set(chosenConfiguration.configRows.map(configRow => configRow.conditionSettings.fact));
    const remainingNumericFacts = factNamesNumeric.filter(fact => !selectedFacts.has(fact.value));
    const remainingBooleanFacts = factNamesBoolean.filter(fact => !selectedFacts.has(fact.value));
    const remainingStringFacts = factNamesString.filter(fact => !selectedFacts.has(fact.value));

    if (remainingNumericFacts.length > 0) {
      return { fact: remainingNumericFacts[0].value, minValue: null, maxValue: null };
    } else if (remainingBooleanFacts.length > 0) {
      return { fact: remainingBooleanFacts[0].value, value: false };
    } else if (remainingStringFacts.length > 0) {
      return { fact: remainingBooleanFacts[0].value, value: false };
    }

    return null;
  };
  const handleChangeValue = (event: any, configurationIndex: number, rowIndex: number | null, fieldName: string) => {
    const updateConfigurations = _.cloneDeep(configurations);

    if (rowIndex === null) {
      // no conditions, just configuration fields
      _.set(updateConfigurations, `[${configurationIndex}].${fieldName}`, event.target.value);
    } else {
      const updatedRows = [...configurations[configurationIndex].configRows];
      // String inputs
      if (_.has(updatedRows, `[${rowIndex}].conditionSettings.exactMatch`)) {
        _.set(updatedRows, `[${rowIndex}].conditionSettings.${fieldName}`, event.target.value);
        _.set(updateConfigurations, `[${configurationIndex}].configRows`, updatedRows);
      } else {
        let value = _.isEmpty(event.target.value) ? null : event.target.value;
        if (isNaN(value)) {
          switch (value) {
            case "true":
              value = true;
              break;
            case "false":
              value = false;
              break;
            default:
              return;
          }
        }
        _.set(updatedRows, `[${rowIndex}].conditionSettings.${fieldName}`, value);
        _.set(updateConfigurations, `[${configurationIndex}].configRows`, updatedRows);
      }
    }
    setConfigurations(updateConfigurations);
  };
  const handleAddProductionConfiguration = () => {
    //Default initial values per config
    setConfigurations([
      ...configurations,
      {
        name: configurations.length === 0 ? "Base Configuration" : `Configuration ${configurations.length + 1}`,
        configRows: [],
        dealerCostSource: "api",
        dealerCost: 0,
        dealerMarkup: 0,
        dealerMarkupType: MARKUP_TYPE.PERCENTAGE,
        minPricing: 0,
        maxPricing: 0,
        suggestedRetailPricing: 0,
        type: AftermarketProductType.Product,
      },
    ]);
  };
  const handleChangeFact = (
    event: React.ChangeEvent<{ value: unknown }>,
    configurationIndex: number,
    rowIndex: number
  ) => {
    const newCriteriaRows = configurations[configurationIndex].configRows;
    const updatedRows = [...newCriteriaRows];
    const isBoolean = Object.keys(FACT_NAMES_BOOLEAN).includes(String(event.target.value));
    const isNumeric = Object.keys(FACT_NAMES_NUMERIC).includes(String(event.target.value));

    if (isBoolean) {
      updatedRows[rowIndex].conditionSettings = {
        fact: event.target.value as FACT_NAMES_BOOLEAN,
        value: false,
      };
    } else if (isNumeric) {
      updatedRows[rowIndex].conditionSettings = {
        fact: event.target.value as FACT_NAMES_NUMERIC,
        minValue: null,
        maxValue: null,
      };
    } else {
      updatedRows[rowIndex].conditionSettings = {
        fact: event.target.value as FACT_NAMES_STRING,
        exactMatch: "",
      };
    }
    //Update configuration nested values
    const updateConfigurations = _.cloneDeep(configurations);
    _.set(updateConfigurations, `[${configurationIndex}].configRows`, updatedRows);
    setConfigurations(updateConfigurations);
  };

  const renderNumericInputByFactType = (fact: string) => {
    switch (fact) {
      case FACT_NAMES_NUMERIC.DEAL_SIZE:
        return CustomCurrentyInput;
      default:
        return undefined;
    }
  };

  const renderStringInputByFactType = (configurationIndex: number, rowIndex: number, configRow: any) => {
    switch (configRow.conditionSettings.fact) {
      case FACT_NAMES_STRING.EQUIPMENT_CONDITION:
        const equipmentConditionOptions = Object.keys(EQUIPMENT_CONDITION).map(key => ({
          code: key,
          label: EQUIPMENT_CONDITION[key as keyof typeof EQUIPMENT_CONDITION],
        }));
        return (
          <Autocomplete
            size="small"
            multiple={true}
            defaultValue={_.get(configRow, "conditionSettings.exactMatch") || []}
            onChange={(event, equipmentConditionOption) => {
              return handleChangeValue(
                {
                  target: {
                    value: equipmentConditionOption.map(equipCond => equipCond.code),
                  },
                },
                configurationIndex,
                rowIndex,
                "exactMatch"
              );
            }}
            options={equipmentConditionOptions}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={option => `${capitalize(option.label)}`}
            renderInput={params => <TextField {...params} label="Select the Equipment Condition" variant="outlined" />}
          />
        );
      case FACT_NAMES_STRING.FINANCING_TYPE:
        const listToRenderFinancingType = Object.keys(FINANCING_TYPE).map(key => ({
          code: key,
          label: FINANCING_TYPE[key as keyof typeof FINANCING_TYPE],
        }));
        return (
          <Autocomplete
            size="small"
            multiple={true}
            defaultValue={_.get(configRow, "conditionSettings.exactMatch") || []}
            onChange={(event, financingTypes) => {
              return handleChangeValue(
                {
                  target: {
                    value: financingTypes.map(financingType => financingType.code),
                  },
                },
                configurationIndex,
                rowIndex,
                "exactMatch"
              );
            }}
            options={listToRenderFinancingType}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={option => `${capitalize(option.label)}`}
            renderInput={params => <TextField {...params} label="Select a Financing Type" variant="outlined" />}
          />
        );
      case FACT_NAMES_STRING.LEGAL_STRUCTURE:
        const listToRenderLegalStructure = Object.keys(LEGAL_STRUCTURE).map(key => ({
          label: key,
          code: LEGAL_STRUCTURE[key as keyof typeof LEGAL_STRUCTURE],
        }));
        return (
          <Autocomplete
            size="small"
            multiple={true}
            defaultValue={_.get(configRow, "conditionSettings.exactMatch") || []}
            onChange={(event, legalStructures) => {
              return handleChangeValue(
                {
                  target: {
                    value: legalStructures.map(legalStructure => legalStructure.code),
                  },
                },
                configurationIndex,
                rowIndex,
                "exactMatch"
              );
            }}
            options={listToRenderLegalStructure}
            getOptionSelected={(option, value) => option.code === value.code}
            getOptionLabel={option => `${capitalize(option.label)}`}
            renderInput={params => <TextField {...params} label="Select a Legal Structure" variant="outlined" />}
          />
        );
      case FACT_NAMES_STRING.CANADA_REGION:
        const canadaSelectedValues = _.cloneDeep(_.get(configRow, "conditionSettings.exactMatch")) || [];
        const listToRenderCanadaStates = _.cloneDeep(
          Object.keys(CANADA_REGION_CODES).map(key => ({
            label: formatNames(key),
            code: CANADA_REGION_CODES[key as keyof typeof CANADA_REGION_CODES],
          }))
        );

        return (
          <Autocomplete
            id="canada-state-autocomplete"
            key={"canada-state-autocomplete"}
            size="small"
            multiple={true}
            options={listToRenderCanadaStates}
            getOptionSelected={(option, value) => option.code === value.code}
            onChange={(event, states) => {
              return handleChangeValue(
                {
                  target: {
                    value: states.map(state => state.code),
                  },
                },
                configurationIndex,
                rowIndex,
                "exactMatch"
              );
            }}
            getOptionLabel={option => `${option.label} (${option.code})`}
            defaultValue={canadaSelectedValues}
            renderInput={params => <TextField {...params} label="Select a Region" variant="outlined" />}
          />
        );

      case FACT_NAMES_STRING.USA_STATE:
        const contained = _.cloneDeep(_.get(configRow, "conditionSettings.exactMatch")) || [];
        const listToRenderStates = Object.keys(US_REGION_CODES).map(key => ({
          label: formatNames(key),
          code: US_REGION_CODES[key as keyof typeof US_REGION_CODES],
        }));

        return (
          <Autocomplete
            id="usa-state-autocomplete"
            key={"usa-state-autocomplete"}
            size="small"
            multiple={true}
            options={listToRenderStates}
            getOptionSelected={(option, value) => option.code === value.code}
            onChange={(event, states) => {
              return handleChangeValue(
                {
                  target: {
                    value: states.map(state => state.code),
                  },
                },
                configurationIndex,
                rowIndex,
                "exactMatch"
              );
            }}
            getOptionLabel={option => `${option.label} (${option.code})`}
            defaultValue={contained}
            renderInput={params => <TextField {...params} label="Select a State" variant="outlined" />}
          />
        );
      default:
        return (
          <ChipInput
            id={`exact-match-${rowIndex}`}
            onChange={(id: string, value: string[]) =>
              handleChangeValue({ target: { value } }, configurationIndex, rowIndex, "exactMatch")
            }
            initialValues={getStringFactInitialValues(configRow)}
          />
        );
    }
  };

  const currentRowIsBooleanFact = (configurationIndex: number, rowIndex: number) => {
    const newCriteriaRows = configurations[configurationIndex].configRows;
    return Object.keys(FACT_NAMES_BOOLEAN).includes(String(newCriteriaRows[rowIndex].conditionSettings.fact));
  };

  const currentRowIsNumericFact = (configurationIndex: number, rowIndex: number) => {
    const newCriteriaRows = configurations[configurationIndex].configRows;
    return Object.keys(FACT_NAMES_NUMERIC).includes(String(newCriteriaRows[rowIndex].conditionSettings.fact));
  };

  const currentRowIsStringFact = (configurationIndex: number, rowIndex: number) => {
    const newCriteriaRows = configurations[configurationIndex].configRows;
    return Object.keys(FACT_NAMES_STRING).includes(String(newCriteriaRows[rowIndex].conditionSettings.fact));
  };

  const getAvailableFactsForRow = (configurationIndex: number, rowIndex: number) => {
    const newCriteriaRows = configurations[configurationIndex].configRows;
    const selectedFacts = new Set(newCriteriaRows.map(configRow => configRow.conditionSettings.fact));
    const currentFact = newCriteriaRows[rowIndex].conditionSettings.fact;
    selectedFacts.delete(currentFact);

    return availableFacts.filter(fact => !selectedFacts.has(fact.value));
  };

  const handleAddMoreCondition = (configurationIndex: number) => {
    const baseCondition = getNextRowElements(configurationIndex);
    if (!baseCondition) {
      alert("No more available facts to add.");
      return;
    }
    const previousRows = configurations[configurationIndex].configRows;
    const updatedRows = [
      ...previousRows,
      {
        conditionSettings: baseCondition,
      },
    ];
    //Update configuration nested values
    const updateConfigurations = _.cloneDeep(configurations);
    _.set(updateConfigurations, `[${configurationIndex}].configRows`, updatedRows);

    setConfigurations(updateConfigurations);
  };

  const handleRemoveConditionRow = (configurationIndex: number, rowIndex: number) => {
    const previousRows = configurations[configurationIndex].configRows;
    const updatedRows = previousRows.filter((_, i) => i !== rowIndex);
    //Update configuration nested values
    const updateConfigurations = _.cloneDeep(configurations);
    _.set(updateConfigurations, `[${configurationIndex}].configRows`, updatedRows);

    setConfigurations(updateConfigurations);
  };
  if (loadingAftermarketProducts || loadingPartnerLinks) return <div>Loading...</div>;
  return (
    <Grid container spacing={3}>
      {/* Header */}
      <Paper style={{ padding: "20px", width: "100%" }}>
        <Grid item xs={12}>
          <Typography variant="h5">
            <ArrowBackIosIcon
              fontSize="small"
              cursor={1}
              onClick={() => {
                history.push("/aftermarket");
              }}
            />
            Add Aftermarket Product
          </Typography>
          <Button
            variant="contained"
            color="primary"
            startIcon={<SaveIcon />}
            disabled={selectedVendor === null || productName === "" || configurations.length === 0}
            onClick={handleSaveAftermarketProduct}
            style={{ float: "right", marginBottom: "10px" }}
          >
            Save
          </Button>
        </Grid>
      </Paper>
      <Grid item xs={12}>
        <Divider variant="middle" />
      </Grid>

      {/* Step 1: Aftermarket Vendor Section */}
      <Paper style={{ padding: "20px", width: "100%" }}>
        <Grid item xs={12}>
          <Typography variant="subtitle1" color="primary">
            <LooksOneIcon /> Aftermarket Vendor
          </Typography>
          <Typography>Select vendor or add new one</Typography>

          <RadioGroup
            row
            value={vendorOption}
            onChange={e => {
              setSelectedVendor({ label: "", value: "" });
              if (e.target.value === "create" || e.target.value === "select") {
                setVendorOption(e.target.value);
              }
            }}
          >
            <FormControlLabel
              value="select"
              control={<Radio color="primary" />}
              label="Select Vendor"
              checked={vendorOption === "select"}
            />
            {/* <FormControlLabel
              value="create"
              control={<Radio color="primary" />}
              label="Create Vendor"
              checked={vendorOption === "create"}
            /> */}
          </RadioGroup>

          {vendorOption === "select" ? (
            <>
              <Autocomplete
                options={availableVendors}
                getOptionLabel={option => option.label}
                value={selectedVendor}
                size="small"
                onChange={(event, newValue) => {
                  setSelectedVendor(newValue);
                }}
                renderInput={params => (
                  <TextField {...params} label="Select Vendor" variant="outlined" margin="normal" fullWidth />
                )}
              />
            </>
          ) : (
            <TextField
              fullWidth
              label="Vendor Name"
              variant="outlined"
              margin="normal"
              size="small"
              value={selectedVendor?.label}
              required
              // onChange={e => setSelectedVendor(e.target.value)}
            />
          )}
        </Grid>
      </Paper>
      <Grid item xs={12}>
        <Divider variant="fullWidth" />
      </Grid>
      {/* Step 2: Product Configuration Section */}
      {selectedVendor && (
        <>
          <Paper style={{ padding: "20px", width: "100%" }}>
            <Grid item xs={12}>
              <Typography variant="subtitle1" color="primary">
                <LooksTwoIcon /> Product Configuration
              </Typography>
              <RadioGroup
                row
                value={configurationMethod}
                onChange={e => setConfigurationMethod(parseInt(e.target.value))}
              >
                <FormControlLabel value={0} control={<Radio color="primary" />} label="Add Manually" />
                <FormControlLabel value={1} control={<Radio color="primary" />} label="via API" />
              </RadioGroup>

              <TextField
                fullWidth
                label="Product Name"
                variant="outlined"
                margin="normal"
                size="small"
                value={productName}
                onChange={e => setProductName(e.target.value)}
                required
              />

              <Typography>Product Type</Typography>
              <RadioGroup row value={productType} onChange={e => setProductType(e.target.value)}>
                {aftermarketProductCategoryList.map(({ value, label }) => (
                  <FormControlLabel key={value} value={value} control={<Radio color="primary" />} label={label} />
                ))}
              </RadioGroup>
              {/* 
              <Typography>Pricing Method</Typography>
              <RadioGroup row value={pricingMethod} onChange={e => setPricingMethod(e.target.value)}>
                <FormControlLabel value="global" control={<Radio color="primary" />} label="Global" />
                <FormControlLabel
                  value="byConfiguration"
                  control={<Radio color="primary" />}
                  label="By Configuration"
                />
              </RadioGroup> */}
            </Grid>
            <Divider variant="fullWidth" />
            <Tabs value={activeTab} onChange={(e, newValue) => setActiveTab(newValue)} variant="scrollable">
              <Tab label="Base Products" />
              <Tab label="Options" disabled />
            </Tabs>

            {/* Product Configuration Details - What goes under "Base Products" tab */}
            {activeTab === 0 && (
              <Grid item xs={12}>
                {configurations.length === 0 && (
                  <Typography variant="body1">
                    Click on "Add Product Configuration" to start adding configurations.
                  </Typography>
                )}
                {configurations.map((configuration: Configuration, configurationIndex: number) => {
                  return (
                    <Box
                      key={`configuration_${configurationIndex}`}
                      border={1}
                      borderColor="grey.400"
                      borderRadius={4}
                      padding={2}
                    >
                      <Grid container alignItems="center">
                        <IconButton color="primary">
                          <DeleteOutlineIcon color="error" />
                        </IconButton>
                        <Typography variant="body1">
                          [Configuration #{configurationIndex + 1}] - Product <b>{productName}</b>
                        </Typography>
                      </Grid>
                      <RadioGroup
                        row
                        value={_.get(configurations, `[${configurationIndex}].type`) || AftermarketProductType.Product}
                        onChange={e => handleChangeValue(e, configurationIndex, null, "type")}
                      >
                        {aftermarketProductTypeList.map(({ value, label }) => (
                          <FormControlLabel
                            key={value}
                            value={value}
                            control={<Radio color="primary" />}
                            label={label}
                          />
                        ))}
                      </RadioGroup>

                      {configuration.configRows.map((configRow: ConfigurationRow, rowIndex: number) => {
                        return (
                          <Grid
                            container
                            spacing={1}
                            key={rowIndex}
                            justifyContent="flex-start"
                            alignContent="flex-start"
                            alignItems="center"
                          >
                            <span style={{ border: "1px dashed #DEDEDE", borderRadius: "50%" }}>
                              <IconButton
                                aria-label="close"
                                size="small"
                                onClick={() => {
                                  handleRemoveConditionRow(configurationIndex, rowIndex);
                                }}
                              >
                                <RemoveCircleIcon fontSize="small" color="error" />
                              </IconButton>
                            </span>
                            <Grid item xs={5}>
                              <FormControl fullWidth variant="standard">
                                <InputLabel id={`new-fact-label-${rowIndex}`}>Rule</InputLabel>
                                <Select
                                  labelId={`new-fact-label-${rowIndex}`}
                                  id={`new-fact-${rowIndex}`}
                                  value={configRow.conditionSettings.fact}
                                  onChange={e => handleChangeFact(e, configurationIndex, rowIndex)}
                                  label="Rule"
                                >
                                  {getAvailableFactsForRow(configurationIndex, rowIndex).map((fact, index) => (
                                    <MenuItem key={index} value={fact.value}>
                                      {fact.label}
                                    </MenuItem>
                                  ))}
                                </Select>
                              </FormControl>
                            </Grid>
                            {currentRowIsBooleanFact(configurationIndex, rowIndex) && (
                              <>
                                <Grid item xs={4}>
                                  <FormControl fullWidth variant="standard" size="small">
                                    <InputLabel id={`new-operator-label-${rowIndex}`}></InputLabel>
                                    <Select
                                      labelId={`new-operator-label-${rowIndex}`}
                                      id={`new-operator-${rowIndex}`}
                                      value={_.get(configRow, "conditionSettings.value")}
                                      onChange={e => handleChangeValue(e, configurationIndex, rowIndex, "value")}
                                      label="Option"
                                    >
                                      {booleanOperatorNames.map(element => (
                                        <MenuItem key={element.label} value={String(element.value)}>
                                          {element.label}
                                        </MenuItem>
                                      ))}
                                    </Select>
                                  </FormControl>
                                </Grid>
                              </>
                            )}
                            {currentRowIsNumericFact(configurationIndex, rowIndex) && (
                              <>
                                <Grid item xs={2}>
                                  <InputLabel id={`min-value-label-${rowIndex}`}>Min Value</InputLabel>
                                  <TextField
                                    id={`min-value-${rowIndex}`}
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    value={_.get(configRow, `conditionSettings.minValue`)}
                                    onChange={e => handleChangeValue(e, configurationIndex, rowIndex, "minValue")}
                                    fullWidth
                                    InputProps={{
                                      inputComponent: renderNumericInputByFactType(configRow.conditionSettings.fact),
                                    }}
                                  />
                                </Grid>
                                <Grid item xs={2}>
                                  <InputLabel id={`max-value-label-${rowIndex}`}>Max Value</InputLabel>
                                  <TextField
                                    id={`max-value-${rowIndex}`}
                                    variant="standard"
                                    type="text"
                                    size="small"
                                    value={_.get(configRow, `conditionSettings.maxValue`)}
                                    onChange={e => handleChangeValue(e, configurationIndex, rowIndex, "maxValue")}
                                    fullWidth
                                    InputProps={{
                                      inputComponent: renderNumericInputByFactType(configRow.conditionSettings.fact),
                                    }}
                                  />
                                </Grid>
                              </>
                            )}
                            {currentRowIsStringFact(configurationIndex, rowIndex) && (
                              <>
                                <Grid item xs={4}>
                                  {renderStringInputByFactType(configurationIndex, rowIndex, configRow)}
                                </Grid>
                              </>
                            )}
                          </Grid>
                        );
                      })}

                      <Grid container justifyContent="center" spacing={6}>
                        <Grid item xs={12}>
                          {_.isEmpty(configuration.configRows) ? "No conditions added yet." : ""}
                        </Grid>
                        <Grid item>
                          <Button
                            startIcon={<AddIcon />}
                            variant="contained"
                            color="primary"
                            onClick={() => {
                              handleAddMoreCondition(configurationIndex);
                            }}
                            size="small"
                          >
                            ADD MORE
                          </Button>
                        </Grid>
                      </Grid>
                      <Grid container justifyContent="center" spacing={6}>
                        <Grid item xs={12}>
                          <Divider></Divider>
                        </Grid>
                      </Grid>

                      <Grid container spacing={3} style={{ marginTop: 20 }}>
                        {/* <Grid item xs={4}>
                          <Typography>Dealer Cost Source</Typography>
                          <RadioGroup
                            row
                            value={vendorOption}
                            id={`dealer-cost-source-${configurationIndex}`}
                            onChange={e => handleChangeValue(e, configurationIndex, null, "dealerCostSource")}
                            name="dealerCostSource"
                          >
                            <FormControlLabel
                              value={"api"}
                              checked={_.get(configurations, `[${configurationIndex}].dealerCostSource`) === "api"}
                              control={<Radio color="primary" />}
                              label="Vendor API"
                            />
                            <FormControlLabel
                              value={"manual"}
                              checked={_.get(configurations, `[${configurationIndex}].dealerCostSource`) === "manual"}
                              control={<Radio color="primary" />}
                              label="Manual"
                            />
                          </RadioGroup>
                        </Grid> */}
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            id={`dealer-cost-${configurationIndex}`}
                            variant="outlined"
                            label="Dealer Cost"
                            size="small"
                            margin="normal"
                            value={_.get(configurations, `[${configurationIndex}].dealerCost`) || ""}
                            InputProps={{
                              inputComponent: CustomCurrentyInput,
                            }}
                            onChange={e => handleChangeValue(e, configurationIndex, null, "dealerCost")}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <Grid container justifyContent="flex-end" spacing={1}>
                            <Grid item xs={8}>
                              {_.get(configurations, `[${configurationIndex}].dealerMarkupType`) ===
                              MARKUP_TYPE.PERCENTAGE ? (
                                <TextField
                                  fullWidth
                                  size="small"
                                  id={`dealer-markup-${configurationIndex}`}
                                  label="Dealer Markup %"
                                  variant="standard"
                                  value={_.get(configurations, `[${configurationIndex}].dealerMarkup`) || ""}
                                  onChange={e => handleChangeValue(e, configurationIndex, null, "dealerMarkup")}
                                />
                              ) : (
                                <TextField
                                  fullWidth
                                  size="small"
                                  id={`dealer-markup-${configurationIndex}`}
                                  label="Dealer Markup $"
                                  variant="standard"
                                  value={_.get(configurations, `[${configurationIndex}].dealerMarkup`) || ""}
                                  InputProps={{
                                    inputComponent: CustomCurrentyInput,
                                  }}
                                  onChange={e => handleChangeValue(e, configurationIndex, null, "dealerMarkup")}
                                />
                              )}
                            </Grid>
                            <Grid item xs={4}>
                              <RadioGroup
                                row
                                id={`dealer-markup-type-${configurationIndex}`}
                                value={vendorOption}
                                onChange={e => handleChangeValue(e, configurationIndex, null, "dealerMarkupType")}
                                name="dealerMarkupType"
                              >
                                <FormControlLabel
                                  value={"percentage"}
                                  checked={
                                    _.get(configurations, `[${configurationIndex}].dealerMarkupType`) ===
                                    MARKUP_TYPE.PERCENTAGE
                                  }
                                  control={<Radio color="primary" />}
                                  label="%"
                                />
                                <FormControlLabel
                                  value={"fixed"}
                                  checked={
                                    _.get(configurations, `[${configurationIndex}].dealerMarkupType`) ===
                                    MARKUP_TYPE.FIXED
                                  }
                                  control={<Radio color="primary" />}
                                  label="$"
                                />
                              </RadioGroup>
                            </Grid>
                          </Grid>
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            id={`suggestedRetailPricing-${configurationIndex}`}
                            label="Suggested Retail Pricing"
                            variant="outlined"
                            size="small"
                            value={_.get(configurations, `[${configurationIndex}].suggestedRetailPricing`) || ""}
                            InputProps={{
                              inputComponent: CustomCurrentyInput,
                            }}
                            onChange={e => handleChangeValue(e, configurationIndex, null, "suggestedRetailPricing")}
                          />
                        </Grid>
                      </Grid>
                      <Grid container spacing={3}>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            id={`minPricing-${configurationIndex}`}
                            label="Min. Pricing"
                            variant="outlined"
                            size="small"
                            value={_.get(configurations, `[${configurationIndex}].minPricing`) || ""}
                            InputProps={{
                              inputComponent: CustomCurrentyInput,
                            }}
                            onChange={e => handleChangeValue(e, configurationIndex, null, "minPricing")}
                          />
                        </Grid>
                        <Grid item xs={4}>
                          <TextField
                            fullWidth
                            id={`maxPricing-${configurationIndex}`}
                            label="Max. Pricing"
                            variant="outlined"
                            size="small"
                            value={_.get(configurations, `[${configurationIndex}].maxPricing`) || ""}
                            InputProps={{
                              inputComponent: CustomCurrentyInput,
                            }}
                            onChange={e => handleChangeValue(e, configurationIndex, null, "maxPricing")}
                          />
                        </Grid>
                      </Grid>
                    </Box>
                  );
                })}
              </Grid>
            )}

            {/* Options Details - What goes under "Options" tab */}
            {activeTab === 1 && (
              <Grid item xs={12}>
                <Typography variant="body2">Upcoming...</Typography>
              </Grid>
            )}
          </Paper>
          <Grid item xs={12}>
            <Divider variant="fullWidth" />
          </Grid>
        </>
      )}

      {selectedVendor && (
        <Grid item xs={12}>
          <Button
            variant="contained"
            color="primary"
            size="small"
            startIcon={<AddIcon />}
            onClick={handleAddProductionConfiguration}
          >
            ADD PRODUCT CONFIGURATION
          </Button>
        </Grid>
      )}
    </Grid>
  );
};

const mapStateToProps = (state: { userProfile: any; account: any; vp: any }) => ({
  userProfile: state.userProfile,
  account: state.account,
  vp: state.vp,
});

export default connect(mapStateToProps, null)(AddAftermarketProduct);
