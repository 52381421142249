import React, { useState } from "react";
import ContractTable from "./Contract";
import AddNewContractModal from "./AddContractModal";
import { Box, Typography, Button } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import { ContentViewType } from "../../../../../global";
import clsx from "clsx";
import { isDesktop, isMobile } from "../../../../../utils/contentViewType";

interface Props {
  vo: any;
  refetchVO: any;
  contentViewType: ContentViewType;
}

export const ContractTab = ({ vo, refetchVO, contentViewType }: Props) => {
  const classes = useStyles();

  const [isAddNewContractModalOpen, setIsAddNewContractModalOpen] = useState<boolean>(false);

  return (
    <>
      <Box className={classes.container}>
        <Box
          className={clsx(classes.panel, {
            [classes.panelDesktop]: isDesktop(contentViewType),
            [classes.panelMobile]: isMobile(contentViewType),
          })}
        >
          <Typography component="span" variant="subtitle2">
            Contract
          </Typography>

          <Button
            size="small"
            color="primary"
            variant="outlined"
            onClick={() => {
              setIsAddNewContractModalOpen(true);
            }}
          >
            Update Contract
          </Button>
        </Box>

        <ContractTable vo={vo} />
      </Box>

      <AddNewContractModal
        vo={vo}
        refetchVO={refetchVO}
        VOId={vo.vendorOpportunityId}
        open={isAddNewContractModalOpen}
        handleClose={() => {
          setIsAddNewContractModalOpen(false);
        }}
      />
    </>
  );
};

const useStyles = makeStyles({
  container: {
    gap: "12px",
    display: "flex",
    marginTop: "16px",
    flexDirection: "column",
    justifyContent: "flex-start",
  },
  panel: {
    display: "flex",
    justifyContent: "space-between",
  },
  panelDesktop: {
    alignItems: "center",
    flexDirection: "row",
  },
  panelMobile: {
    gap: "4px",
    flexDirection: "column",
    alignItems: "flex-start",
  },
});
