import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import { Grid, Typography } from "@material-ui/core";
import { formatCurrency } from "../utils";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import _ from "lodash";
import { useDrop } from "react-dnd";

const styles = {
  card: {
    backgroundColor: "rgba(20, 115, 230, 0.3)",
    borderRadius: "8px",
    display: "inline-block",
    boxSizing: "border-box",
    margin: "6px 10px",
    padding: "12px",
    "& p": {
      margin: 0,
    },
  },
  root: {
    border: "1px solid #e6e6e6",
    borderRadius: "8px",
    minHeight: 350,
    display: "flex",
    flexDirection: "column",
    justifyContent: "flex-start",
    flexBasis: "28%", // Ensure each section takes up 1/3rd of the width
    minWidth: "28%",
    flexGrow: 1,
    boxSizing: "border-box", // Ensures padding/margin is accounted for in width
  },
  optionHeader: {
    padding: "16px",
    borderTopLeftRadius: "8px",
    borderTopRightRadius: "8px",
    position: "relative",
    minHeight: 70,
  },
  productPackagesContainer: {
    minHeight: "100px",
    display: "flex",
    flexDirection: "column",
    flexGrow: 1,
  },
  productPackageListContainer: {
    listStyle: "none",
    padding: "0 12px",
    "& > li:not(:last-child)": {
      marginBottom: 15,
    },
  },
  productPackageListItemText: {
    marginLeft: 10,
  },
  productListContainer: {
    paddingLeft: 51,
  },
  menuOptionConfigurableItemActions: {
    position: "absolute",
    top: 5,
    right: 7,
  },
  propductPackageConfigurableItemActions: {
    marginLeft: "auto",
  },
  financeProfitText: {
    color: "green",
    fontWeight: "bold",
  },
};

const useStyles = makeStyles(styles);

const ProposalProductAddons = ({ addons }) => {
  const addonObject = {};
  addons.forEach(addon => {
    let { category } = addon;
    const { title, cost } = addon;
    if (!category) {
      category = "Options";
    }
    if (!addonObject[category]) {
      addonObject[category] = [];
    }
    addonObject[category].push({ title, cost });
  });
  return Object.keys(addonObject).map(category => (
    <div key={category}>
      <span>{category}</span>
      <ul>
        {addonObject[category].map(option => (
          <li key={option.title}>
            {option.title} - {option.cost}
          </li>
        ))}
      </ul>
    </div>
  ));
};

const ProposalOptionCard = ({
  term,
  paymentAmountPerPeriod,
  menuName,
  menuOptionName,
  titleColor,
  titleBgColor,
  packages,
  dropZoneId, // Unique identifier for each drop zone
}) => {
  const classes = useStyles();
  const [droppedData, setDroppedData] = useState(null);

  const [{ canDrop, isOver }, drop] = useDrop(
    () => ({
      accept: "ITEM",
      drop: item => {
        setDroppedData(prevData => ({
          ...prevData,
          [dropZoneId]: _.cloneDeep(item),
        }));
      },
      collect: monitor => ({
        isOver: !!monitor.isOver(),
        canDrop: !!monitor.canDrop(),
      }),
    }),
    [dropZoneId]
  );

  const isActive = canDrop && isOver;

  return (
    <div ref={drop} className={classes.root} style={{ backgroundColor: isActive ? "lightgreen" : "transparent" }}>
      {menuOptionName ? (
        <>
          <div className={classes.optionHeader} style={{ backgroundColor: titleBgColor }}>
            <Typography variant="h5" align="center" style={{ color: titleColor, fontWeight: 400 }}>
              {menuOptionName}
            </Typography>
          </div>
          <div className={classes.productPackagesContainer}>
            <ul className={classes.productPackageListContainer}>
              {_.some(packages) ? (
                packages.concat(_.get(droppedData, `[${dropZoneId}].productsToAdd`, [])).map(pp => (
                  <li key={pp.proposalPackageId} className={classes.productPackageListItem}>
                    <Grid container xs={12} style={{ flexWrap: "nowrap" }}>
                      <CheckCircleIcon style={{ color: titleBgColor }} />
                      <Typography className={classes.productPackageListItemText}>{pp.name}</Typography>
                    </Grid>
                    {_.some(pp.products) && (
                      <Grid container xs={12}>
                        <ul className={classes.productListContainer}>
                          {_.map(pp.products, p => (
                            <li key={p.proposalProductId}>
                              <Typography variant="body2">
                                {p.title} {formatCurrency(p.retailCost)}
                              </Typography>
                              {p.addons && <ul>{<ProposalProductAddons addons={p.addons} />}</ul>}
                            </li>
                          ))}
                        </ul>
                      </Grid>
                    )}
                  </li>
                ))
              ) : (
                <li>
                  <Typography align="center">No Product Packages</Typography>
                </li>
              )}
            </ul>
          </div>
          <Typography align="center" paragraph={true}>
            <p style={{ display: "flex", flexDirection: "column", margin: 0 }}>
              <span style={{ fontWeight: "bold", color: "rgba(0,0,0,0.85)", fontSize: 20 }}>
                {formatCurrency(paymentAmountPerPeriod)}
              </span>
              <span className={styles.greyText} style={{ fontSize: 12 }}>
                per month
              </span>
            </p>
          </Typography>
        </>
      ) : (
        <div className={classes.card}>
          <p style={{ fontSize: "24px", fontWeight: 400 }}>{formatCurrency(paymentAmountPerPeriod)}/mo.</p>
          <p className={styles.greyText}>for {term} months</p>
        </div>
      )}
    </div>
  );
};

export default ProposalOptionCard;
